import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/src/theme/agency';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/Agency/agency.style';
import Navbar from '../containers/Agency/Navbar';

import WorkHistory from '../containers/Rewards/WorkHistory';

import Footer from '../containers/Agency/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

const rewardTemplate = data => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Agency" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar logoURL={queryResult.logo.publicURL} />
            </DrawerProvider>
          </Sticky>
          <WorkHistory
            titleText={queryResult.title}
            descriptionText={queryResult.description}
          />
          <Footer
            logoURL={queryResult.logo.publicURL}
            footerData={queryResult.footer}
          /> */}
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};

export default rewardTemplate;

// export const queryData = graphql`
//   query queryRPageQuery($id: String) {
//     strapiRewardpage(id: { eq: $id }) {
//       title
//       description
//       logo {
//         publicURL
//       }
//       footer {
//         email
//         phone
//       }
//     }
//   }
// `;
